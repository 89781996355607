import axios, { AxiosError } from 'axios'
import { Either, left, right } from 'fp-ts/Either'

export interface FormData {
  name: string
  lastname?: string
  phone?: string
  email: string
  website?: string
  country?: string
  privacy_policy: boolean
  referrer: string
  user_ip?: string
}

export interface SupportFormSenderBackendConfiguration {
  formURL: string
}

export type SupportFormSenderBackend = (
  data: FormData,
) => Promise<Either<AxiosError, void>>

export const SupportFormSenderBackend =
  ({
    formURL,
  }: SupportFormSenderBackendConfiguration): SupportFormSenderBackend =>
  async (data): Promise<Either<AxiosError, void>> => {
    try {
      await axios({
        method: 'POST',
        url: formURL,
        headers: {
          'Content-type': 'application/json',
        },
        data,
      })
      return right(undefined)
    } catch (error) {
      return left(error as any)
    }
  }

export interface SupportFormSenderSlackConfiguration {
  webhookURL: string
}

export type SupportFormSenderSlack = (input: {
  data: FormData
  backendError?: AxiosError
}) => Promise<Either<AxiosError, void>>

export const SupportFormSenderSlack =
  ({
    webhookURL,
  }: SupportFormSenderSlackConfiguration): SupportFormSenderSlack =>
  async ({ data, backendError }) => {
    const message = `*RICEVUTA NUOVA LEAD*
*Pagina di invio:* ${data.referrer}

*Nome:* ${data.name}
*Cognome:* ${data.lastname}
*Telefono:* ${data.phone}
*Email:* ${data.email}
*Sito web:* ${data.website}
*Paese:* ${data.country}
*Accettazione privacy:* ${data.privacy_policy}`

    const backendFeedBackMessage = backendError
      ? `
*Attenzione!!*
*Non è stato possibile salvare il messaggio sul CMS, né procedere all'invio di email.*
*QUESTA È L'UNICA TRACCIA DELLA LEAD RICEVUTA*

*Errore:* ${backendError.response?.status} ${backendError.name}
${JSON.stringify(backendError.response?.data, null, 2)}


  `
      : ''

    try {
      await axios({
        method: 'POST',
        url: webhookURL,
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
        },
        data: JSON.stringify({
          username: backendError ? '[ERROR] Support Form' : 'Support Form',
          text: [message, backendFeedBackMessage].join('\n\n'),
        }),
      })
      return right(undefined)
    } catch (error) {
      return left(error as any)
    }
  }

import styled from '@emotion/styled'
import { FormStripe } from 'app/components/FormStripe'
import { MainParagraphStripe } from 'app/components/MainParagraphStripe'
import { SEO } from 'app/components/SEO'
import {
  CustomerReviews,
  Props as CustomerReviewsProps,
} from 'app/containers/CustomerReviews'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import {
  MainLeadGeneration,
  Props as MainLeadGenerationProps,
} from 'app/containers/MainLeadGeneration'
import { Navigation, Props as NavigationProps } from 'app/containers/Navigation'
import { SupportForm } from 'app/containers/SupportForm'
import {
  SupportFormSenderBackend,
  SupportFormSenderBackendConfiguration,
  SupportFormSenderSlack,
  SupportFormSenderSlackConfiguration,
} from 'app/utils/SupportFormSender'
import { isLeft } from 'fp-ts/lib/These'
import { PageProps } from 'gatsby'
import React, { memo, useMemo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  navigationProps?: NavigationProps
  mainLeadGenerationProps?: MainLeadGenerationProps
  customerReviewsProps?: CustomerReviewsProps
  footerProps?: FooterProps
  supportFormSenderBackendConfiguration: SupportFormSenderBackendConfiguration
  supportFormSenderSlackConfiguration: SupportFormSenderSlackConfiguration
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function SupportPageTemplate({
  pageContext,
}: PageProps<void, PageContext>) {
  const context = pageContext as any

  const sendFormToBackend = useMemo(
    () =>
      SupportFormSenderBackend(
        pageContext.supportFormSenderBackendConfiguration,
      ),
    [pageContext.supportFormSenderBackendConfiguration],
  )
  const sendFormToSlack = useMemo(
    () =>
      SupportFormSenderSlack(pageContext.supportFormSenderSlackConfiguration),
    [pageContext.supportFormSenderSlackConfiguration],
  )

  return (
    <Container>
      <SEO {...context.seoProps} />
      {context.navigationProps ? (
        <Navigation
          pageID={context.id}
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          {...context.navigationProps}
        />
      ) : null}
      {context.mainParagraphStripeProps ? (
        <MainParagraphStripe {...context.mainParagraphStripeProps} />
      ) : null}
      {context.formStripeProps ? (
        <FormStripe {...context.formStripeProps} />
      ) : null}
      <SupportForm
        languageCode={pageContext.languageCode}
        postSendRedirectPath={`${
          pageContext.languagePrefix ? `/${pageContext.languagePrefix}` : ''
        }/thank-you/?contact-requested`}
        onSupportFormSendToBackend={async (data) => {
          const result = await sendFormToBackend(data)
          await sendFormToSlack({
            data,
            backendError: isLeft(result) ? result.left : undefined,
          })
          return result
        }}
      />
      {context.customerReviewsProps ? (
        <CustomerReviews
          languageCode={pageContext.languageCode}
          {...context.customerReviewsProps}
        />
      ) : null}
      {context.mainLeadGenerationProps ? (
        <MainLeadGeneration
          languageCode={pageContext.languageCode}
          {...context.mainLeadGenerationProps}
        />
      ) : null}
      {context.footerProps ? (
        <Footer
          languageCode={pageContext.languageCode}
          languagePrefix={pageContext.languagePrefix}
          {...context.footerProps}
        />
      ) : null}
    </Container>
  )
})

const Container = styled.main``
